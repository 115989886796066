/* eslint-disable @typescript-eslint/no-explicit-any */
export var registrations = [{
  name: 'LightingBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LightingBanner" */
    '@stories/Widgets/Banners/LightingBanner/LightingBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Banner',
  import: function _import() {
    return import(
    /* webpackChunkName: "Banner" */
    '@stories/Widgets/Banners/Banner/Banner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CentreText',
  import: function _import() {
    return import(
    /* webpackChunkName: "CentreText" */
    '@stories/Widgets/Content/CentreText/CentreText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FAQList',
  import: function _import() {
    return import(
    /* webpackChunkName: "FAQList" */
    '@stories/Widgets/Content/FAQList/FAQList');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'IconWithText',
  import: function _import() {
    return import(
    /* webpackChunkName: "IconWithText" */
    '@stories/Widgets/Content/IconWithText/IconWithText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LightingPreview',
  import: function _import() {
    return import(
    /* webpackChunkName: "LightingPreview" */
    '@stories/Widgets/Media/LightingPreview/LightingPreview');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ImageSlider',
  import: function _import() {
    return import(
    /* webpackChunkName: "ImageSlider" */
    '@stories/Widgets/Media/ImageSlider/ImageSlider');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SnackbarManager',
  import: function _import() {
    return import(
    /* webpackChunkName: "SnackbarManager" */
    '@stories/Widgets/Snackbars/SnackbarManager/SnackbarManager');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'NavigationTypeahead',
  import: function _import() {
    return import(
    /* webpackChunkName: "NavigationTypeahead" */
    '@stories/Components/Search/NavigationTypeahead/NavigationTypeahead');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HubRegistration',
  import: function _import() {
    return import(
    /* webpackChunkName: "HubRegistration" */
    '@stories/Widgets/Forms/HubRegistration/HubRegistration');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Signpost7030',
  import: function _import() {
    return import(
    /* webpackChunkName: "Signpost7030" */
    '@stories/Widgets/Content/Signpost7030/Signpost7030');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DrainCleanersSplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "DrainCleanersSplitContent" */
    '@stories/Widgets/Content/DrainCleanersSplitContent/DrainCleanersSplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'IconBar',
  import: function _import() {
    return import(
    /* webpackChunkName: "IconBar" */
    '@stories/Widgets/Content/IconBar/IconBar');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'AccessorySelector',
  import: function _import() {
    return import(
    /* webpackChunkName: "AccessorySelector" */
    '@stories/Widgets/Products/AccessorySelector/AccessorySelector');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'JSSRegistration',
  import: function _import() {
    return import(
    /* webpackChunkName: "JSSRegistration" */
    '@stories/Widgets/Forms/JSSRegistration/JSSRegistration');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SimpleCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "SimpleCards" */
    '@stories/Widgets/Content/SimpleCards/SimpleCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FeaturedPanel',
  import: function _import() {
    return import(
    /* webpackChunkName: "FeaturedPanel" */
    '@stories/Widgets/Content/FeaturedPanel/FeaturedPanel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DetailedCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "DetailedCards" */
    '@stories/Widgets/Content/DetailedCards/DetailedCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SystemsSignposts',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemsSignposts" */
    '@stories/Widgets/Content/SystemsSignposts/SystemsSignposts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'M12M18Banner',
  import: function _import() {
    return import(
    /* webpackChunkName: "M12M18Banner" */
    '@stories/Widgets/Banners/M12M18Banner/M12M18Banner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Signpost5050',
  import: function _import() {
    return import(
    /* webpackChunkName: "Signpost5050" */
    '@stories/Widgets/Content/Signpost5050/Signpost5050');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SystemsChargerCTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemsChargerCTA" */
    '@stories/Widgets/Content/SystemsChargerCTA/SystemsChargerCTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'OneKeySignpost',
  import: function _import() {
    return import(
    /* webpackChunkName: "OneKeySignpost" */
    '@stories/Widgets/Content/OneKeySignpost/OneKeySignpost');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TabbedProducts',
  import: function _import() {
    return import(
    /* webpackChunkName: "TabbedProducts" */
    '@stories/Widgets/Products/TabbedProducts/TabbedProducts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'VideoCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "VideoCarousel" */
    '@stories/Widgets/Media/VideoCarousel/VideoCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SignUp',
  import: function _import() {
    return import(
    /* webpackChunkName: "SignUp" */
    '@stories/Widgets/Forms/SignUp/SignUp');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SignUpStacked',
  import: function _import() {
    return import(
    /* webpackChunkName: "SignUpStacked" */
    '@stories/Widgets/Forms/SignUpStacked/SignUpStacked');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductGallery',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductGallery" */
    '@stories/Widgets/Products/ProductGallery/ProductGallery');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'NewProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "NewProductCarousel" */
    '@stories/Widgets/Products/NewProductCarousel/NewProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductRangeRoot',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductRangeRoot" */
    '@stories/Widgets/Products/ProductRange/ProductRange');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeBanner" */
    '@stories/Widgets/Banners/TradeBanner/TradeBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeCardListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeCardListing" */
    '@stories/Widgets/Content/TradeCardListing/TradeCardListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'OutdoorBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "OutdoorBanner" */
    '@stories/Widgets/Banners/OutdoorBanner/OutdoorBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CategoryListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "CategoryListing" */
    '@stories/Widgets/Content/CategoryListing/CategoryListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FullWidthContentCTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "FullWidthContentCTA" */
    '@stories/Widgets/Content/FullWidthContentCTA/FullWidthContentCTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'GalleryWithNav',
  import: function _import() {
    return import(
    /* webpackChunkName: "GalleryWithNav" */
    '@stories/Widgets/Media/GalleryWithNav/GalleryWithNav');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductAttachmentSelector',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductAttachmentSelector" */
    '@stories/Widgets/Products/ProductAttachmentSelector/ProductAttachmentSelector');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductDetailVideos',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductDetailVideos" */
    '@stories/Widgets/ProductDetail/ProductDetailVideos/ProductDetailVideos');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PageLanguageSelector',
  import: function _import() {
    return import(
    /* webpackChunkName: "PageLanguageSelector" */
    '@stories/Widgets/Content/PageLanguageSelector/PageLanguageSelector');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LaserAnimation',
  import: function _import() {
    return import(
    /* webpackChunkName: "LaserAnimation" */
    '@stories/Widgets/Media/LaserAnimation/LaserAnimation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PageBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "PageBanner" */
    '@stories/Widgets/Banners/PageBanner/PageBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'VideoWithText',
  import: function _import() {
    return import(
    /* webpackChunkName: "VideoWithText" */
    '@stories/Widgets/Media/VideoWithText/VideoWithText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CalloutCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "CalloutCards" */
    '@stories/Widgets/Content/CalloutCards/CalloutCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'WarrantyBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "WarrantyBanner" */
    '@stories/Widgets/Content/WarrantyBanner/WarrantyBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ImageWithBorder',
  import: function _import() {
    return import(
    /* webpackChunkName: "ImageWithBorder" */
    '@stories/Widgets/Content/ImageWithBorder/ImageWithBorder');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Newsletter',
  import: function _import() {
    return import(
    /* webpackChunkName: "Newsletter" */
    '@stories/Widgets/Content/Newsletter/Newsletter');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TripleText',
  import: function _import() {
    return import(
    /* webpackChunkName: "TripleText" */
    '@stories/Widgets/Content/TripleText/TripleText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SystemCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemCards" */
    '@stories/Widgets/Media/SystemCards/SystemCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CategoryCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "CategoryCards" */
    '@stories/Widgets/Media/CategoryCards/CategoryCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'TradeCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeCards" */
    '@stories/Widgets/Media/TradeCards/TradeCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductCards" */
    '@stories/Widgets/Products/ProductCards/ProductCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SocialBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "SocialBanner" */
    '@stories/Widgets/Banners/SocialBanner/SocialBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ContentBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "ContentBanner" */
    '@stories/Widgets/Banners/ContentBanner/ContentBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'FullWidthVideoBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "FullWidthVideoBanner" */
    '@stories/Widgets/Banners/FullWidthVideoBanner/FullWidthVideoBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'VideoBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "VideoBanner" */
    '@stories/Widgets/Banners/VideoBanner/VideoBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CompetitionForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "CompetitionForm" */
    '@stories/Widgets/Forms/CompetitionForm/CompetitionForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PPEBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "PPEBanner" */
    '@stories/Widgets/Banners/PPEBanner/PPEBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PPESplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "PPESplitContent" */
    '@stories/Widgets/Content/PPESplitContent/PPESplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PPEJobsite',
  import: function _import() {
    return import(
    /* webpackChunkName: "PPEJobsite" */
    '@stories/Widgets/Media/PPEJobsite/PPEJobsite');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PPESupportingContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "PPESupportingContent" */
    '@stories/Widgets/Content/PPESupportingContent/PPESupportingContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "CTA" */
    '@stories/Widgets/Content/CTA/CTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductFinder',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductFinder" */
    '@stories/Components/Products/ProductFinder/ProductFinder');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MultiplePinBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "MultiplePinBanner" */
    '@stories/Widgets/Banners/MultiplePinBanner/MultiplePinBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TextWithFullWidthImage',
  import: function _import() {
    return import(
    /* webpackChunkName: "TextWithFullWidthImage" */
    '@stories/Widgets/Content/TextWithFullWidthImage/TextWithFullWidthImage');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'InteractiveRange',
  import: function _import() {
    return import(
    /* webpackChunkName: "InteractiveRange" */
    '@stories/Widgets/Products/InteractiveRange/InteractiveRange');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'CentredCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "CentredCarousel" */
    '@stories/Widgets/Media/CentredCarousel/CentredCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'DrainCleanersApplication',
  import: function _import() {
    return import(
    /* webpackChunkName: "DrainCleanersApplication" */
    '@stories/Widgets/Media/DrainCleanersApplication/DrainCleanersApplication');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DrainCleanersKitBuilder',
  import: function _import() {
    return import(
    /* webpackChunkName: "DrainCleanersKitBuilder" */
    '@stories/Widgets/Products/DrainCleanersKitBuilder/DrainCleanersKitBuilder');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TabbedTools',
  import: function _import() {
    return import(
    /* webpackChunkName: "TabbedTools" */
    '@stories/Widgets/Products/TabbedTools/TabbedTools');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'RepeatContentScroll',
  import: function _import() {
    return import(
    /* webpackChunkName: "RepeatContentScroll" */
    '@stories/Widgets/Content/RepeatContentScroll/RepeatContentScroll');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'StandardsBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "StandardsBanner" */
    '@stories/Widgets/Banners/StandardsBanner/StandardsBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CareersSplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "CareersSplitContent" */
    '@stories/Widgets/Content/CareersSplitContent/CareersSplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TestimonialSlider',
  import: function _import() {
    return import(
    /* webpackChunkName: "TestimonialSlider" */
    '@stories/Widgets/Media/TestimonialSlider/TestimonialSlider');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CareersMap',
  import: function _import() {
    return import(
    /* webpackChunkName: "CareersMap" */
    '@stories/Widgets/Media/CareersMap/CareersMap');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PopupSignUp',
  import: function _import() {
    return import(
    /* webpackChunkName: "PopupSignUp" */
    '@stories/Widgets/Forms/PopupSignUp/PopupSignUp');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'LightingCalculatorWidget',
  import: function _import() {
    return import('@stories/Widgets/Products/LightingCalculator/LightingCalculatorWidget/LightingCalculatorWidget');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StandardsContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "StandardsContent" */
    '@stories/Widgets/Content/Standards/StandardsContent/StandardsContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'RespiratorsContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "RespiratorsContent" */
    '@stories/Widgets/Content/Standards/RespiratorsContent/RespiratorsContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SafetyGlassesContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "SafetyGlassesContent" */
    '@stories/Widgets/Content/Standards/SafetyGlassesContent/SafetyGlassesContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'KneePadsContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "KneePadsContent" */
    '@stories/Widgets/Content/Standards/KneePadsContent/KneePadsContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HearingProtectionContent',
  import: function _import() {
    return import('@stories/Widgets/Content/Standards/HearingProtectionContent/HearingProtectionContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersIntroduction',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersIntroduction" */
    '@stories/Widgets/Content/LasersIntroduction/LasersIntroduction');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersBanner" */
    '@stories/Widgets/Banners/LasersBanner/LasersBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersOverviewIcons',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersOverviewIcons" */
    '@stories/Widgets/Content/LasersOverviewIcons/LasersOverviewIcons');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersTabbedOverview',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersTabbedOverview" */
    '@stories/Widgets/Content/LasersTabbedOverview/LasersTabbedOverview');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersPopupForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersPopupForm" */
    '@stories/Components/Forms/LasersPopupForm/LasersPopupForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersComparisonVideo',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersComparisonVideo" */
    '@stories/Widgets/Content/LasersComparisonVideo/LasersComparisonVideo');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DualImageAndText',
  import: function _import() {
    return import(
    /* webpackChunkName: "DualImageAndText" */
    '@stories/Widgets/Content/DualImageAndText/DualImageAndText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LasersPreviewVideos',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersPreviewVideos" */
    '@stories/Widgets/Content/LasersPreviewVideos/LasersPreviewVideos');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DualProductCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "DualProductCards" */
    '@stories/Widgets/Content/DualProductCards/DualProductCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductSEOCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductSEOCarousel" */
    '@stories/Widgets/Content/ProductSEOCarousel/ProductSEOCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductFeatures',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductFeatures" */
    '@stories/Widgets/Content/ProductFeatures/ProductFeatures');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'Breadcrumb',
  import: function _import() {
    return import(
    /* webpackChunkName: "Breadcrumb" */
    '@stories/Components/Navigation/Breadcrumb/Breadcrumb');
  },
  config: {
    hydrate: false,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HeatedGearIcon',
  import: function _import() {
    return import(
    /* webpackChunkName: "HeatedGearIcon" */
    '@stories/Widgets/Content/HeatedGearIcon/HeatedGearIcon');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'VerticalCarouselBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "VerticalCarouselBanner" */
    '@stories/Widgets/Banners/VerticalCarouselBanner/VerticalCarouselBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HeatedGearTabbed',
  import: function _import() {
    return import(
    /* webpackChunkName: "HeatedGearTabbed" */
    '@stories/Widgets/Content/HeatedGearTabbed/HeatedGearTabbed');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ImageGallery',
  import: function _import() {
    return import(
    /* webpackChunkName: "ImageGallery" */
    '@stories/Widgets/Media/ImageGallery/ImageGallery');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'InteractiveVideo',
  import: function _import() {
    return import(
    /* webpackChunkName: "InteractiveVideo" */
    '@stories/Widgets/Media/InteractiveVideo/InteractiveVideo');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductListing" */
    '@stories/Widgets/Products/ProductListing/ProductListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductList',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductList" */
    '@stories/Components/Products/ProductList/ProductList');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'TradesProductListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradesProductListing" */
    '@stories/Widgets/Products/TradesProductListing/TradesProductListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SearchResults',
  import: function _import() {
    return import(
    /* webpackChunkName: "SearchResults" */
    '@stories/Widgets/Products/SearchResults/SearchResults');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'InnerNavigation',
  import: function _import() {
    return import(
    /* webpackChunkName: "InnerNavigation" */
    '@stories/Widgets/Navigation/InnerNavigation/InnerNavigation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'InnerBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "InnerBanner" */
    '@stories/Widgets/Banners/InnerBanner/InnerBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductCarousel" */
    '@stories/Widgets/Products/ProductCarousel/ProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ContentVideo',
  import: function _import() {
    return import(
    /* webpackChunkName: "ContentVideo" */
    '@stories/Widgets/Media/ContentVideo/ContentVideo');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StickyNavigation',
  import: function _import() {
    return import(
    /* webpackChunkName: "StickyNavigation" */
    '@stories/Widgets/Navigation/StickyNavigation/StickyNavigation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SplitContentBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "SplitContentBanner" */
    '@stories/Widgets/Banners/SplitContentBanner/SplitContentBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FullWidthImageFeature',
  import: function _import() {
    return import(
    /* webpackChunkName: "FullWidthImageFeature" */
    '@stories/Widgets/Content/FullWidthImageFeature/FullWidthImageFeature');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelProductComparison',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelProductComparison" */
    '@stories/Widgets/Products/MxFuelProductComparison/MxFuelProductComparison');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelProductDownloads',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelProductDownloads" */
    '@stories/Widgets/Products/MxFuelProductDownloads/MxFuelProductDownloads');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'Interactive360Image',
  import: function _import() {
    return import(
    /* webpackChunkName: "Interactive360Image" */
    '@stories/Widgets/Products/Interactive360Image/Interactive360Image');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelProductHero',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelProductHero" */
    '@stories/Widgets/Products/MXFuelProductHero/MxFuelProductHero');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductReviewsListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductReviewsListing" */
    '@stories/Widgets/ProductDetail/ProductReviews/ProductReviewsListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'Unsubscribe',
  import: function _import() {
    return import(
    /* webpackChunkName: "Unsubscribe" */
    '@stories/Widgets/Forms/Unsubscribe/Unsubscribe');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TextBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "TextBanner" */
    '@stories/Widgets/Banners/TextBanner/TextBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'Signpost6040',
  import: function _import() {
    return import(
    /* webpackChunkName: "Signpost6040" */
    '@stories/Widgets/Banners/Signpost6040/Signpost6040');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FeatureProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "FeatureProductCarousel" */
    '@stories/Widgets/Products/FeatureProductCarousel/FeatureProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PinBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "PinBanner" */
    '@stories/Widgets/Banners/PinBanner/PinBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MasterContactForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "MasterContactForm" */
    '@stories/Widgets/Forms/MasterContactForm/MasterContactForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LightingContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "LightingContent" */
    '@stories/Widgets/Content/LightingContent/LightingContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'NumberedCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "NumberedCards" */
    '@stories/Widgets/Content/NumberedCards/NumberedCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ColourRenderingContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "ColourRenderingContent" */
    '@stories/Widgets/Content/ColourRenderingContent/ColourRenderingContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'BatteryTypeCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "BatteryTypeCards" */
    '@stories/Widgets/Content/BatteryTypeCards/BatteryTypeCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductDetails',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductDetails" */
    '@stories/Widgets/ProductDetail/ProductDetails/ProductDetails');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'WhatsIncluded',
  import: function _import() {
    return import(
    /* webpackChunkName: "WhatsIncluded" */
    '@stories/Widgets/ProductDetail/WhatsIncluded/WhatsIncluded');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductSpecifications',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductSpecifications" */
    '@stories/Widgets/ProductDetail/ProductSpecifications/ProductSpecifications');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'Downloads',
  import: function _import() {
    return import(
    /* webpackChunkName: "Downloads" */
    '@stories/Widgets/ProductDetail/Downloads/Downloads');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductFeaturesText',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductFeaturesText" */
    '@stories/Widgets/ProductDetail/ProductFeaturesText/ProductFeaturesText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StoreLocatorCTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "StoreLocatorCTA" */
    '@stories/Widgets/ProductDetail/StoreLocatorCTA/StoreLocatorCTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'GlobalStyles',
  import: function _import() {
    return import(
    /* webpackChunkName: "GlobalStyles" */
    '@stories/GlobalStyles/GlobalStyles');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CookiePolicy',
  import: function _import() {
    return import(
    /* webpackChunkName: "CookiePolicy" */
    '@stories/Widgets/Content/CookiePolicy/CookiePolicy');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CookieText',
  import: function _import() {
    return import(
    /* webpackChunkName: "CookieText" */
    '@stories/Widgets/Content/CookieText/CookieText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SteelStorageFeatures',
  import: function _import() {
    return import(
    /* webpackChunkName: "SteelStorageFeatures" */
    '@stories/Widgets/Content/SteelStorageFeatures/SteelStorageFeatures');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SteelStorageInteractiveVideosWrapper',
  import: function _import() {
    return import('@stories/Widgets/Media/SteelStorageInteractiveVideosWrapper/SteelStorageInteractiveVideosWrapper');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'REDemptionCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "REDemptionCards" */
    '@stories/Widgets/Products/REDemptionCards/REDemptionCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'REDemptionBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "REDemptionBanner" */
    '@stories/Widgets/Banners/REDemptionBanner/REDemptionBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Footer',
  import: function _import() {
    return import(
    /* webpackChunkName: "Footer" */
    '@stories/Widgets/Footer/Footer');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'DownloadCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "DownloadCards" */
    '@stories/Widgets/Content/DownloadCards/DownloadCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductCarouselWithBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductCarouselWithBanner" */
    '@stories/Widgets/Content/ProductCarouselWithBanner/ProductCarouselWithBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'NailersFeatures',
  import: function _import() {
    return import(
    /* webpackChunkName: "NailersFeatures" */
    '@stories/Widgets/Content/NailersFeatures/NailersFeatures');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelVideoBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelVideoBanner" */
    '@stories/Widgets/Banners/MxFuelVideoBanner/MxFuelVideoBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TwoToneBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "TwoToneBanner" */
    '@stories/Widgets/Banners/TwoToneBanner/TwoToneBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelHoverProduct',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelHoverProduct" */
    '@stories/Widgets/Content/MxFuelHoverProduct/MxFuelHoverProduct');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelZeroEmissions',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelZeroEmissions" */
    '@stories/Widgets/Content/MxFuelZeroEmissions/MxFuelZeroEmissions');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelIcons',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelIcons" */
    '@stories/Widgets/Content/MxFuelIcons/MxFuelIcons');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StepByStepContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "StepByStepContent" */
    '@stories/Widgets/Content/StepByStepContent/StepByStepContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MxFuelAnimatedScroll',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelAnimatedScroll" */
    '@stories/Widgets/Content/MxFuelAnimatedScroll/MxFuelAnimatedScroll');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'GroupedProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "GroupedProductCarousel" */
    '@stories/Widgets/Products/GroupedProductCarousel/GroupedProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Header',
  import: function _import() {
    return import(
    /* webpackChunkName: "Header" */
    '@stories/Widgets/Header/Header');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'DetailedProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "DetailedProductCarousel" */
    '@stories/Widgets/Products/DetailedProductCarousel/DetailedProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ScrollToTop',
  import: function _import() {
    return import(
    /* webpackChunkName: "ScrollToTop" */
    '@stories/Elements/Misc/ScrollToTop/ScrollToTop');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'ParallaxBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "ParallaxBanner" */
    '@stories/Widgets/Banners/ParallaxBanner/ParallaxBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TwoToneContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "TwoToneContent" */
    '@stories/Widgets/Content/TwoToneContent/TwoToneContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FullWidthBannerWithContent',
  import: function _import() {
    return import('@stories/Widgets/Banners/FullWidthBannerWithContent/FullWidthBannerWithContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FullWidthText',
  import: function _import() {
    return import(
    /* webpackChunkName: "FullWidthText" */
    '@stories/Widgets/Content/FullWidthText/FullWidthText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SplitCTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "SplitCTA" */
    '@stories/Widgets/Content/SplitCTA/SplitCTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'GenericImageText',
  import: function _import() {
    return import(
    /* webpackChunkName: "GenericImageText" */
    '@stories/Widgets/Content/GenericImageText/GenericImageText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'AspectRatioBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "AspectRatioBanner" */
    '@stories/Widgets/Banners/AspectRatioBanner/AspectRatioBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'EventDateBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "EventDateBanner" */
    '@stories/Widgets/Banners/EventDateBanner/EventDateBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'EventMap',
  import: function _import() {
    return import(
    /* webpackChunkName: "EventMap" */
    '@stories/Widgets/Media/EventMap/EventMap');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'BlitzForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "BlitzForm" */
    '@stories/Widgets/Forms/BlitzForm/BlitzForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StoreLocator',
  import: function _import() {
    return import('@stories/Widgets/Content/StoreLocator/StoreLocator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CountdownTimer',
  import: function _import() {
    return import(
    /* webpackChunkName: "CountdownTimer" */
    '@stories/Widgets/Content/CountdownTimer/CountdownTimer');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductDetailsOverview',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductDetailsOverview" */
    '@stories/Widgets/ProductDetail/ProductToolOverview/ProductToolOverview');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'LeadingBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LeadingBanner" */
    '@stories/Widgets/Banners/LeadingBanner/LeadingBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ApplicationGallery',
  import: function _import() {
    return import(
    /* webpackChunkName: "ApplicationGallery" */
    '@stories/Widgets/ProductDetail/ApplicationGallery/ApplicationGallery');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductNavigation',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductNavigation" */
    '@stories/Widgets/ProductDetail/ProductNavigation/ProductNavigation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductSuggestions',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductSuggestions" */
    '@stories/Widgets/ProductDetail/ProductSuggestions/ProductSuggestions');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'SocialLinks',
  import: function _import() {
    return import(
    /* webpackChunkName: "SocialLinks" */
    '@stories/Widgets/Banners/SocialLinks/SocialLinks');
  },
  config: {
    hydrate: false,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FilteredGallery',
  import: function _import() {
    return import(
    /* webpackChunkName: "FilteredGallery" */
    '@stories/Widgets/Media/FilteredGallery/FilteredGallery');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'VideoSeekerCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "VideoSeekerCarousel" */
    '@stories/Widgets/Media/VideoSeekerCarousel/VideoSeekerCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradesStickyNavigation',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradesStickyNavigation" */
    '@stories/Widgets/Navigation/TradesStickyNavigation/TradesStickyNavigation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ScrollingCallouts',
  import: function _import() {
    return import(
    /* webpackChunkName: "ScrollingCallouts" */
    '@stories/Widgets/Content/ScrollingCallouts/ScrollingCallouts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradesInnerBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradesInnerBanner" */
    '@stories/Widgets/Banners/TradesInnerBanner/TradesInnerBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductDetailPopUp',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductDetailPopUp" */
    '@stories/Widgets/Products/ProductDetailPopUp/ProductDetailPopUp');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'LegacyManualsAndSparePartsButton',
  import: function _import() {
    return import(
    /* webpackChunkName: "LegacyManualsAndSparePartsButton" */
    '@stories/Widgets/Products/ProductDetailPopUp/LegacyManualsAndSparePartsButton');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ServiceCentreLocator',
  import: function _import() {
    return import(
    /* webpackChunkName: "ServiceCentreLocator" */
    '@stories/Widgets/Content/ServiceCentreLocator/ServiceCentreLocatorRoot');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FoamInsertsConfigurator',
  import: function _import() {
    return import(
    /* webpackChunkName: "FoamInsertsConfigurator" */
    '@stories/Widgets/Products/FoamInsertsConfigurator/FoamInsertsConfigurator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HeaderText',
  import: function _import() {
    return import(
    /* webpackChunkName: "HeaderText" */
    '@stories/Widgets/Content/HeaderText/HeaderText');
  },
  config: {
    hydrate: false,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'MXFuelSplitVideoBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "MXFuelSplitVideoBanner" */
    '@stories/Widgets/Media/MXFuelVideoBanner/MXFuelVideoBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'EventRegistrationForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "EventRegistrationForm" */
    '@stories/Widgets/Forms/EventRegistrationForm/EventRegistrationForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PreferencesFormRoot',
  import: function _import() {
    return import(
    /* webpackChunkName: "PreferencesFormRoot" */
    '@stories/Widgets/Forms/PreferencesForm/PreferencesFormRoot');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'WhatsNewBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "WhatsNewBanner" */
    '@stories/Widgets/Banners/WhatsNewBanner/WhatsNewBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HighOutputLandingBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "HighOutputLandingBanner" */
    '@stories/Widgets/Banners/HighOutputLandingBanner/HighOutputLandingBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HighOutputGraphs',
  import: function _import() {
    return import(
    /* webpackChunkName: "HighOutputGraphs" */
    '@stories/Widgets/Content/HighOutputGraphs/HighOutputGraphs');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HighOutputSplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "HighOutputSplitContent" */
    '@stories/Widgets/Content/HighOutputSplitContent/HighOutputSplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HighOutputLetterboxSection',
  import: function _import() {
    return import(
    /* webpackChunkName: "HighOutputLetterboxSection" */
    '@stories/Widgets/Content/HighOutputLetterboxSection/HighOutputLetterboxSection');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PackoutSubmission',
  import: function _import() {
    return import(
    /* webpackChunkName: "PackoutSubmission" */
    '@stories/Widgets/Forms/PackoutSubmission/PackoutSubmission');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LogoBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LogoBanner" */
    '@stories/Widgets/Banners/LogoBanner/LogoBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'JSSProductReviews',
  import: function _import() {
    return import(
    /* webpackChunkName: "JSSProductReviews" */
    '@stories/Widgets/Forms/JSSProductReviews/JSSProductReviews');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PlatinumServiceSearch',
  import: function _import() {
    return import(
    /* webpackChunkName: "PlatinumServiceSearch" */
    '@stories/Widgets/Products/PlatinumServiceSearch/PlatinumServiceSearch');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FuelBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "FuelBanner" */
    '@stories/Widgets/Banners/FuelBanner/FuelBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CareIcons',
  import: function _import() {
    return import(
    /* webpackChunkName: "CareIcons" */
    '@stories/Widgets/ProductDetail/CareIcons/CareIcons');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Bolt100ThreeKitConfigurator',
  import: function _import() {
    return import(
    /* webpackChunkName: "Bolt100ThreeKitConfigurator" */
    '@stories/Widgets/ProductDetail/Bolt100ThreeKitConfigurator/Bolt100ThreeKitConfigurator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Bolt200ThreeKitConfigurator',
  import: function _import() {
    return import(
    /* webpackChunkName: "Bolt200ThreeKitConfigurator" */
    '@stories/Widgets/ProductDetail/Bolt200ThreeKitConfigurator/Bolt200ThreeKitConfigurator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'EventNDAForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "EventNDAForm" */
    '@stories/Widgets/Forms/EventNDAForm/EventNDAForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ContentGeneral',
  import: function _import() {
    return import(
    /* webpackChunkName: "ContentGeneral" */
    '@stories/Widgets/Content/ContentGeneral/ContentGeneral');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'DownloadListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "DownloadListing" */
    '@stories/Widgets/Content/DownloadListing/DownloadListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Accordion',
  import: function _import() {
    return import(
    /* webpackChunkName: "Accordion" */
    '@stories/Widgets/Content/Accordion/Accordion');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'AccountAreaSignInSignUp',
  import: function _import() {
    return import(
    /* webpackChunkName: "AccountAreaSignInSignUp" */
    '@stories/Widgets/Forms/AccountAreaSignInSignUp/AccountAreaSignInSignUp');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'AccountAreaRoot',
  import: function _import() {
    return import(
    /* webpackChunkName: "AccountAreaRoot" */
    '@stories/Widgets/Content/AccountArea/AccountAreaRoot');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'AccountAreaPasswordResetForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "AccountAreaPasswordResetForm" */
    '@stories/Widgets/Forms/AccountAreaPasswordResetForm/AccountAreaPasswordResetForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HtmlContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "HtmlContent" */
    '@stories/Widgets/Content/HtmlContent/HtmlContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view'
  }
}, {
  name: 'TabbedCTA',
  import: function _import() {
    return import(
    /* webpackChunkName: "TabbedCTA" */
    '@stories/Widgets/Content/TabbedCTA/TabbedCTA');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TripleImageSplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "TripleImageSplitContent" */
    '@stories/Widgets/Content/TripleImageSplitContent/TripleImageSplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ProductFAQs',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductFAQs" */
    '@stories/Widgets/ProductDetail/ProductFAQs/ProductFAQs');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ScrollCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "ScrollCarousel" */
    '@stories/Widgets/Media/ScrollCarousel/ScrollCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PromotionSignUpPopUp',
  import: function _import() {
    return import(
    /* webpackChunkName: "PromotionSignUpPopUp" */
    '@stories/Widgets/Forms/PromotionSignUpPopUp/PromotionSignUpPopUp');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: true
  }
}, {
  name: 'PromotionAddressCollectionForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "PromotionAddressCollectionForm" */
    '@stories/Widgets/Forms/PromotionAddressCollectionForm/PromotionAddressCollectionForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'ConfigurableCompetitionForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "ConfigurableCompetitionForm" */
    '@stories/Widgets/Forms/ConfigurableCompetitionForm/ConfigurableCompetitionForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'UnderstandingTheFeatures',
  import: function _import() {
    return import(
    /* webpackChunkName: "UnderstandingTheFeatures" */
    '@stories/Widgets/ProductDetail/UnderstandingTheFeatures/UnderstandingTheFeatures');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'ToolFinder',
  import: function _import() {
    return import(
    /* webpackChunkName: "ToolFinder" */
    '@stories/Widgets/Products/ToolFinder/ToolFinder');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'AdvancedEventRegistrationForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "AdvancedEventRegistrationForm" */
    '@stories/Widgets/Forms/AdvancedEventRegistrationForm/AdvancedEventRegistrationForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'SignpostCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "SignpostCarousel" */
    '@stories/Widgets/Content/SignpostCarousel/SignpostCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'CarouselCardsWithText',
  import: function _import() {
    return import(
    /* webpackChunkName: "CarouselCardsWithText" */
    '@stories/Widgets/Content/CarouselCardsWithText/CarouselCardsWithText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'NumberedBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "NumberedBanner" */
    '@stories/Widgets/Content/NumberedBanner/NumberedBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ExtendedText',
  import: function _import() {
    return import(
    /* webpackChunkName: "ExtendedText" */
    '@stories/Widgets/Content/ExtendedText/ExtendedText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StaticHtml',
  import: function _import() {
    return import(
    /* webpackChunkName: "StaticHtml" */
    '@stories/Widgets/Content/StaticHtml/StaticHtml');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'TradeHeroBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeHeroBanner" */
    '@stories/Widgets/Banners/TradeHeroBanner/TradeHeroBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Staggered5050SplitContent',
  import: function _import() {
    return import(
    /* webpackChunkName: "Staggered5050SplitContent" */
    '@stories/Widgets/Content/Staggered5050SplitContent/Staggered5050SplitContent');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeApplicationProductListingPreview',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeApplicationProductListingPreview" */
    '@stories/Widgets/Content/TradeApplicationProductListingPreview/TradeApplicationProductListingPreview');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeDownloadsListing',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeDownloadsListing" */
    '@stories/Widgets/Content/TradeDownloadsListing/TradeDownloadsListing');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeTestimonialCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeTestimonialCarousel" */
    '@stories/Widgets/Content/TradeTestimonialCarousel/TradeTestimonialCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradeArticleListingPreview',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradeArticleListingPreview" */
    '@stories/Widgets/Content/TradeArticleListingPreview/TradeArticleListingPreview');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FullWidthVideoCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "FullWidthVideoCarousel" */
    '@stories/Widgets/Media/FullWidthVideoCarousel/FullWidthVideoCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'StaggeredContentCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "StaggeredContentCarousel" */
    '@stories/Widgets/Content/StaggeredContentCarousel/StaggeredContentCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'LayeredDualImageBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LayeredDualImageBanner" */
    '@stories/Widgets/Content/LayeredDualImageBanner/LayeredDualImageBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'HighlightedFeaturesProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "HighlightedFeaturesProductCarousel" */
    '@stories/Widgets/Content/HighlightedFeaturesProductCarousel/HighlightedFeaturesProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'TradesAlternateStickyNavigation',
  import: function _import() {
    return import(
    /* webpackChunkName: "TradesAlternateStickyNavigation" */
    '@stories/Widgets/Navigation/TradesAlternateStickyNavigation/TradesAlternateStickyNavigation');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'VirtualTourAccess',
  import: function _import() {
    return import(
    /* webpackChunkName: "VirtualTourAccess" */
    '@stories/Widgets/Forms/VirtualTourAccess/VirtualTourAccess');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'PressReleaseListingRoot',
  import: function _import() {
    return import(
    /* webpackChunkName: "PressReleaseListingRoot" */
    '@stories/Widgets/Content/PressRelease/PressReleaseListing/PressReleaseListingRoot');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'PressReleaseDetail',
  import: function _import() {
    return import(
    /* webpackChunkName: "PressReleaseDetail" */
    '@stories/Widgets/Content/PressRelease/PressReleaseDetail/PressReleaseDetail');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'PackoutSignpost',
  import: function _import() {
    return import(
    /* webpackChunkName: "PackoutSignpost" */
    '@stories/Widgets/Content/PackoutSignpost/PackoutSignpost');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'MXFuelVideoCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "MXFuelVideoCarousel" */
    '@stories/Widgets/Media/MXFuelVideoCarousel/MXFuelVideoCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'MXCalculator',
  import: function _import() {
    return import(
    /* webpackChunkName: "MXCalculator" */
    '@stories/Widgets/Products/MXCalculator/MXCalculator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ParamsAnchor',
  import: function _import() {
    return import(
    /* webpackChunkName: "ParamsAnchor" */
    '@stories/Elements/Misc/ParamsAnchor/ParamsAnchor');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'ZeroEmissions',
  import: function _import() {
    return import(
    /* webpackChunkName: "ZeroEmissions" */
    '@stories/Widgets/Content/ZeroEmissions/ZeroEmissions');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'MxFuelBenefits',
  import: function _import() {
    return import(
    /* webpackChunkName: "MxFuelBenefits" */
    '@stories/Widgets/Content/MxFuelBenefits/MxFuelBenefits');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'TwoColumnText',
  import: function _import() {
    return import(
    /* webpackChunkName: "TwoColumnText" */
    '@stories/Widgets/Content/TwoColumnText/TwoColumnText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'UpcomingEvents',
  import: function _import() {
    return import(
    /* webpackChunkName: "UpcomingEvents" */
    '@stories/Widgets/Content/UpcomingEvents/UpcomingEvents');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SplitAnchorCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "UpcomingEvents" */
    '@stories/Widgets/Content/SplitAnchorCards/SplitAnchorCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'GuaranteeSplitDetailCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "GuaranteeSplitDetailCards" */
    '@stories/Widgets/Content/GuaranteeSplitDetailCards/GuaranteeSplitDetailCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'HandtoolsGuarantee',
  import: function _import() {
    return import(
    /* webpackChunkName: "HandtoolsGuarantee" */
    '@stories/Widgets/Content/HandtoolsGuarantee/HandtoolsGuarantee');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'KeyFeaturesShowcase',
  import: function _import() {
    return import(
    /* webpackChunkName: "KeyFeaturesShowcase" */
    '@stories/Widgets/Content/KeyFeaturesShowcase/KeyFeaturesShowcase');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'SystemsShowcaseDuo',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemsShowcaseDuo" */
    '@stories/Widgets/Content/SystemsShowcaseDuo/SystemsShowcaseDuo');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'M18FuelBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "M18FuelBanner" */
    '@stories/Widgets/Content/M18FuelBanner/M18FuelBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'AuthorisedDistributor',
  import: function _import() {
    return import('@stories/Widgets/Content/AuthorisedDistributor/AuthorisedDistributor');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'Hotspot360',
  import: function _import() {
    return import(
    /* webpackChunkName: "Hotspot360" */
    '@stories/Widgets/ProductDetail/Hotspot360/Hotspot360');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'OnlineBlitzSteps',
  import: function _import() {
    return import(
    /* webpackChunkName: "OnlineBlitzSteps" */
    '@stories/Widgets/Content/OnlineBlitzSteps/OnlineBlitzSteps');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductLandingProducts',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductLandingProducts" */
    '@stories/Widgets/Content/ProductLandingProducts/ProductLandingProducts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'CtaImageGrid',
  import: function _import() {
    return import(
    /* webpackChunkName: "CtaImageGrid" */
    '@stories/Widgets/Content/CtaImageGrid/CtaImageGrid');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ChargeTimeTable',
  import: function _import() {
    return import(
    /* webpackChunkName: "ChargeTimeTable" */
    '@stories/Widgets/Content/ChargeTimeTable/ChargeTimeTable');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'LasersDemoCta',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersDemoCta" */
    '@stories/Widgets/Content/LasersDemoCta/LasersDemoCta');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'LasersRange',
  import: function _import() {
    return import(
    /* webpackChunkName: "LasersRange" */
    '@stories/Widgets/Content/LasersRange/LasersRange');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'TextWithGallery',
  import: function _import() {
    return import(
    /* webpackChunkName: "TextWithGallery" */
    '@stories/Widgets/Content/TextWithGallery/TextWithGallery');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'AboutUsCallout',
  import: function _import() {
    return import(
    /* webpackChunkName: "AboutUsCallout" */
    '@stories/Widgets/Content/AboutUsCallout/AboutUsCallout');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SteelStorageConfigurator',
  import: function _import() {
    return import(
    /* webpackChunkName: "SteelStorageConfigurator" */
    '@stories/Widgets/Products/SteelStorageConfigurator/SteelStorageConfigurator');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false //setting this to true breaks the component

  }
}, {
  name: 'HotspotProductRange',
  import: function _import() {
    return import(
    /* webpackChunkName: "HotspotProductRange" */
    '@stories/Widgets/Products/HotspotProductRange/HotspotProductRange');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'AboutUsTimeline',
  import: function _import() {
    return import(
    /* webpackChunkName: "AboutUsTimeline" */
    '@stories/Widgets/Content/AboutUsTimeline/AboutUsTimeline');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ImageGrid',
  import: function _import() {
    return import(
    /* webpackChunkName: "ImageGrid" */
    '@stories/Widgets/Content/ImageGrid/ImageGrid');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'LargeBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "LargeBanner" */
    '@stories/Widgets/Banners/LargeBanner/LargeBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ScrollingTextBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "ScrollingTextBanner" */
    '@stories/Widgets/Banners/ScrollingTextBanner/ScrollingTextBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'IntroductionText',
  import: function _import() {
    return import(
    /* webpackChunkName: "IntroductionText" */
    '@stories/Widgets/Content/IntroductionText/IntroductionText');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgePowerScale',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgePowerScale" */
    '@stories/Widgets/Content/ForgePowerScale/ForgePowerScale');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeSizeCallout',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeSizeCallout" */
    '@stories/Widgets/Content/ForgeSizeCallout/ForgeSizeCallout');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeChargeCallouts',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeChargeCallouts" */
    '@stories/Widgets/Content/ForgeChargeCallouts/ForgeChargeCallouts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeProductCards',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeProductCards" */
    '@stories/Widgets/Products/ForgeProductCards/ForgeProductCards');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeProductCallouts',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeProductCallouts" */
    '@stories/Widgets/Products/ForgeProductCallouts/ForgeProductCallouts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeRuntimeCallouts',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeRuntimeCallouts" */
    '@stories/Widgets/Content/ForgeRuntimeCallouts/ForgeRuntimeCallouts');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeChargeComparisonTable',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeChargeComparisonTable" */
    '@stories/Widgets/Content/ForgeChargeComparisonTable/ForgeChargeComparisonTable');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'Callout5050',
  import: function _import() {
    return import(
    /* webpackChunkName: "Callout5050" */
    '@stories/Widgets/Content/Callout5050/Callout5050');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeSignpost',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeSignpost" */
    '@stories/Widgets/Content/ForgeSignpost/ForgeSignpost');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeCooling',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeCooling" */
    '@stories/Widgets/Content/ForgeCooling/ForgeCooling');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeWalkaround',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeWalkaround" */
    '@stories/Widgets/Content/ForgeWalkaround/ForgeWalkaround');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SystemFooterBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemFooterBanner" */
    '@stories/Widgets/Content/SystemFooterBanner/SystemFooterBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeMXFuelBanner',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeMXFuelBanner" */
    '@stories/Widgets/Content/ForgeMXFuelBanner/ForgeMXFuelBanner');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'AlgoliaSearchResults',
  import: function _import() {
    return import(
    /* webpackChunkName: "AlgoliaSearchResults" */
    '@stories/Widgets/Products/AlgoliaSearchResults/AlgoliaSearchResults');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'Loader',
  import: function _import() {
    return import(
    /* webpackChunkName: "Loader" */
    '@stories/Elements/Misc/Loader/Loader');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'CookiePopup',
  import: function _import() {
    return import(
    /* webpackChunkName: "CookiePopup" */
    '@stories/Widgets/Misc/CookiePopup/CookiePopup');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'always',
    blockedByOnetrust: false
  }
}, {
  name: 'PromotionForm',
  import: function _import() {
    return import(
    /* webpackChunkName: "PromotionForm" */
    '@stories/Widgets/Forms/PromotionForm/PromotionForm');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'SystemSelector',
  import: function _import() {
    return import(
    /* webpackChunkName: "SystemSelector" */
    '@stories/Widgets/Content/SystemSelector/SystemSelector');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeTabbedProductCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeTabbedProductCarousel" */
    '@stories/Widgets/Content/ForgeTabbedProductCarousel/ForgeTabbedProductCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ForgeCellTechnologies',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeCellTechnologies" */
    '@stories/Widgets/Content/ForgeCellTechnologies/ForgeCellTechnologies');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeSizeCalloutScrollJack',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeSizeCalloutScrollJack" */
    '@stories/Widgets/Content/ForgeSizeCallout/ForgeSizeCalloutScrollJack');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'ForgeCoolCycleFlow',
  import: function _import() {
    return import(
    /* webpackChunkName: "ForgeCoolCycleFlow" */
    '@stories/Widgets/Content/ForgeCoolCycleFlow/ForgeCoolCycleFlow');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'YoutubeShortsCarousel',
  import: function _import() {
    return import(
    /* webpackChunkName: "YoutubeShortsCarousel" */
    '@stories/Widgets/Media/YoutubeShortsCarousel/YoutubeShortsCarousel');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}, {
  name: 'FootwearInteractiveVideo',
  import: function _import() {
    return import(
    /* webpackChunkName: "FootwearInteractiveVideo" */
    '@stories/Widgets/Media/FootwearInteractiveVideo/FootwearInteractiveVideo');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: true
  }
}, {
  name: 'ProductListRange',
  import: function _import() {
    return import(
    /* webpackChunkName: "ProductListRange" */
    '@stories/Widgets/Products/ProductListRange/ProductListRange');
  },
  config: {
    hydrate: true,
    hydrateBehaviour: 'in-view',
    blockedByOnetrust: false
  }
}];